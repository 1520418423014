<template>
  <div class="container">
    <strong>ตั้งค่าสมาชิกใหม่</strong>
    <div class="card text-center">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <a class="nav-link" href="#" :class="[{'active':tabActive==='group'}]" @click="tabActive='group'">เปิด/ปิด กลุ่มหวย</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" :class="[{'active':tabActive==='limit'}]" @click="tabActive='limit'">ข้อมูลการแทง</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" :class="[{'active':tabActive==='rate'}]" @click="tabActive='rate'">อัตราจ่าย</a>
          </li>
        </ul>
      </div>

      <NewAccountSettingGroup :data="input" v-if="tabActive==='group'" />
      <NewAccountSettingLimit :data="input" :ref-setting="refSetting" v-if="tabActive==='limit'" />
      <NewAccountSettingRate :data="input" :ref-setting="refSetting" v-if="tabActive==='rate'" />

      <div class="card-footer text-right">
        <button class="btn btn-primary" :disabled="isProcess || !permSetting" @click="saveTemplate">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import ManageAccountService from '@/services/ManageAccountService'
import NewAccountSettingGroup from './components/NewAccountSettingGroup'
import NewAccountSettingLimit from './components/NewAccountSettingLimit'
import NewAccountSettingRate from './components/NewAccountSettingRate'

import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'NewAccountSetting',
  components: {
    NewAccountSettingGroup,
    NewAccountSettingLimit,
    NewAccountSettingRate
  },
  data() {
    return {
      input: null,
      tabActive: 'group',
      refSetting: null,
      isProcess: false
    }
  },
  computed: {
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.NewMemberSetting === 'w'
    }
  },
  methods: {
    loadTemplate() {
      ManageAccountService.getTemplate()
      .then((response)=>{
        console.log('loadTemplate', response)
        if(response.success) {
          this.input = response.data.template.account.groups
          this.refSetting = response.data.refSetting.groups
        }
      })
    },
    saveTemplate() {
      this.isProcess = true
      ManageAccountService.saveTemplate(this.input)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'แก้ไขข้อมูลเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
        this.loadTemplate()
      })
    }
  },
  mounted() {
    this.$store.dispatch('reloadMarketGroups')
    this.$store.dispatch('reloadMarketPayrates')
    this.loadTemplate()
  }
}
</script>
